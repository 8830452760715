import React, { useEffect, useState } from "react";
import { Col, Card, Table, Spinner, Badge } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import {
  getGiftCardsApi,
  getReferenceUser,
  getUserBooingApi,
} from "../../services/User/UserService";
import toast from "react-hot-toast";
import Pagination from "../common/Pagination";
import moment from "moment";

export default function GiftCardManagement(props) {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const limit = 10;

  function getTableData() {
    setLoader(true);
    getGiftCardsApi(currentPage, limit)
      .then((response) => {
        setData(response?.data?.data);
        setLoader(false);
        const total = response.data.data.giftCardCount;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.data || error?.response?.data?.message
        );
        setLoader(false);
      });
  }

  useEffect(() => {
    getTableData();
  }, [currentPage]);

  return (
    <>
      <div>
        <h3 className="p-3">Gift Cards</h3>
      </div>
      <Col>
        <Card>
          <Card.Body>
            {loader ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "50vh" }}
              >
                <Spinner animation="border" />
              </div>
            ) : (
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong> Name</strong>
                    </th>

                    <th>
                      <strong>Sender</strong>
                    </th>
                    <th>
                      <strong>Receiver</strong>
                    </th>
                    <th className="text-center">
                      <strong>code</strong>
                    </th>
                    <th className="text-center">
                      <strong>Used Code</strong>
                    </th>
                    <th>
                      <strong>amount</strong>
                    </th>
                    <th className="text-center">
                      <strong>Remaining</strong>
                    </th>
                    <th>
                      <strong>created At</strong>
                    </th>
                    <th>
                      <strong>Expiration Time</strong>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data?.giftCardListing?.map((item, refIndex) => (
                    <tr key={refIndex}>
                      <td>
                        {item?.name}
                        <br />
                        {item?.email}
                      </td>
                      {/* <td> {item?.email}</td> */}
                      <td>
                        {" "}
                        {item?.senderId?.name} <br />
                        {item?.senderId?.email}
                      </td>
                      <td>
                        {item?.receiverId ? (
                          <>
                            {item?.receiverId?.name} <br />
                            {item?.receiverId?.email}
                          </>
                        ) : (
                          <>--</>
                        )}
                      </td>

                      <td className="text-center">{item?.code}</td>
                      <td
                        className="text-center hover-table"
                        onClick={() =>
                          props.history.push({
                            pathname: "/gift-card-history",
                            state: {
                              item,
                            },
                          })
                        }
                      >
                        {item?.bookingId?.length || 0}
                      </td>

                      <td className="text-center">€{item?.totalAmount || 0}</td>

                      <td className="text-center">€{item?.amount}</td>

                      <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                      <td>
                        {moment(item?.expirationTime).format("DD/MM/YYYY")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {data?.giftCardCount === 0 && !loader && (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            )}
            {data?.giftCardCount !== 0 && !loader && (
              <div className="d-flex justify-content-between align-items-center pt-3">
                <div className="dataTables_info">
                  Total Gifts:
                  <span
                    style={{
                      color: "#9b4444",
                      border: "1px solid #9b4444",
                      padding: "5px 15px",
                      borderRadius: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    {data?.giftCardCount}
                  </span>
                </div>
                <div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
