import React, { useState } from "react";
import { Badge, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import defaultImage from "../../images/emptyImg.jpg";
import pdfIcon from "../../icons/pdf.png";
import ShowImage from "../modal/ShowImage";
import PdfViewer from "../components/PdfViewer";

function UserDetails(props) {
  const vendorData = props.location?.state;
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImage, setShowImage] = useState(false);
  function handleError(e) {
    e.target.src = defaultImage;
  }
  function prevImage(imageUrl) {
    setShowImage(true);
    setSelectedImage(imageUrl);
  }
  return (
    <div>
      {" "}
      <div className="page-titles">
        <h4>Vendor Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="vendor-management">Vendor Management</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Vendor Details</Link>
          </li>
        </ol>
      </div>
      <Card style={{ width: "85%" }}>
        <Card.Header>
          <div className="d-flex" style={{ gap: "1rem", flexGrow: "1" }}>
            <div className="text-left" style={{ flex: "1" }}>
              <div className="d-flex justify-content-between align-items-center">
                <h5 style={{ margin: "0" }}>Name</h5>
                <p className="m-0">{vendorData?.name}</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="m-0">Updated At</h5>
                <p className="m-0">
                  {moment(vendorData?.updatedAt).format("ll")}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h5 style={{ margin: "0" }}>Email</h5>
                <p style={{ margin: "0" }}>{vendorData?.email}</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="m-0">Vendor Commission</h5>
                <p className="m-0">{vendorData?.vendorCommision || 0}%</p>
              </div>

              <div className="d-flex justify-content-between align-items-center ">
                <h5 className="m-0">Phone</h5>
                <p className="m-0">
                  {" "}
                  <span>+{vendorData?.countryCode} </span>
                  {vendorData?.contact}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="m-0">Document</h4>
                {/* <a
                  className="hover-table"
                  target="_blank"
                  href={`https://staging-api.bookwineries.com/${vendorData?.document}`}
                  // download="sample.pdf"
                >
                  <img src={pdfIcon} height={40} />
                </a> */}
                {vendorData?.document?.mimetype?.endsWith("/pdf") ? (
                  <a
                    href={`https://staging-api.bookwineries.com/${vendorData?.document?.path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className=" pointer p-2"
                      src={pdfIcon}
                      height={50}
                      width={50}
                    />
                  </a>
                ) : (
                  <img
                    src={`https://staging-api.bookwineries.com/${vendorData?.document?.path}`}
                    onClick={() => prevImage(vendorData?.document?.path)}
                    height={40}
                    width={50}
                    className="pointer"
                  />
                )}
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="text-black">
            <Card.Header className="p-0">
              <h3>Winery</h3>
            </Card.Header>
            {vendorData?.winery ? (
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>Image</strong>
                    </th>
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>address</strong>
                    </th>
                    <th>
                      <strong>Category</strong>
                    </th>
                    <th>
                      <strong>Guests</strong>
                    </th>
                    <th className="text-center">
                      <strong>price</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {vendorData?.winery?.map((winery) => (
                    <tr className={winery.isDeleted ? "disabled-row" : ""}>
                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "winery-details",
                            state: winery,
                          })
                        }
                      >
                        <img
                          src={`https://staging-api.bookwineries.com/${winery?.photo[0]}`}
                          height={50}
                          width={50}
                          style={{ borderRadius: "25px" }}
                        />
                      </td>
                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "winery-details",
                            state: winery,
                          })
                        }
                      >
                        {winery.name}
                      </td>
                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "winery-details",
                            state: winery,
                          })
                        }
                      >
                        {winery.address}
                      </td>
                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "winery-details",
                            state: winery,
                          })
                        }
                      >
                        {winery.category}
                      </td>
                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "winery-details",
                            state: winery,
                          })
                        }
                      >
                        {winery.category}
                      </td>
                      <td
                        className="text-center"
                        onClick={() =>
                          props.history.push({
                            pathname: "winery-details",
                            state: winery,
                          })
                        }
                      >
                        €{winery.price}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="p-3 d-flex justify-content-center">
                <h4>No results</h4>
              </div>
            )}
            {/* </div> */}
          </div>
        </Card.Body>
      </Card>
      {showImage && (
        <ShowImage
          show={showImage}
          onHide={() => setShowImage(false)}
          image={selectedImage}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {};
export default connect(mapStateToProps)(UserDetails);
