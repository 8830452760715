import React from "react";
import { Modal } from "react-bootstrap";

export default function ShowImage({ show, onHide, image }) {
  return (
    <Modal className="image-view-modal" show={show} onHide={onHide} centered>
      <div>
        <img src={`https://staging-api.bookwineries.com/${image}`} />
      </div>
      <button className="close cross" onClick={() => onHide()}>
        X
      </button>
    </Modal>
  );
}
